import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Img from 'gatsby-image'

import * as styles from './sponsors-display.module.css'

export default function SponsorsDisplay() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTeDxKi(sort: {fields: upcomingEvent___sponsors___name}) {
        edges {
          node {
            upcomingEvent {
              yearIdentifier
              sponsors {
                logo {
                  fluid(maxWidth: 1200) {
                    ...GatsbyContentfulFluid
                 }
                }
                websiteLink
                name
                tier
                type
                reducedSize
              }
            }
          }
        }
      }
    }
  `);

  function sortSponsorsByName(s1, s2) {
    if (s1.name > s2.name) {
      return 1;
    } else if (s1.name < s2.name) {
      return -1;
    } else {
      return 0;
    }
  }

  const contentfulTEDxKI = data.allContentfulTeDxKi.edges[0].node;
  const productSponsors = contentfulTEDxKI.upcomingEvent.sponsors.filter(s => s.type === "product");
  const financialSponsors = contentfulTEDxKI.upcomingEvent.sponsors.filter(s => s.type === "financial");
  const event = {
    yearIdentifier: contentfulTEDxKI.upcomingEvent.yearIdentifier,
    productSponsors: {
      tier1: productSponsors ? productSponsors.filter(s => s.tier === 1).sort(sortSponsorsByName) : null,
      tier2: productSponsors ? productSponsors.filter(s => s.tier === 2).sort(sortSponsorsByName) : null,
      tier3: productSponsors ? productSponsors.filter(s => s.tier === 3).sort(sortSponsorsByName) : null,
    },
    financialSponsors: {
      tier1: financialSponsors ? financialSponsors.filter(s => s.tier === 1).sort(sortSponsorsByName) : null,
      tier2: financialSponsors ? financialSponsors.filter(s => s.tier === 2).sort(sortSponsorsByName) : null,
      tier3: financialSponsors ? financialSponsors.filter(s => s.tier === 3).sort(sortSponsorsByName) : null,
    }
  }
  return (
    <div class="text-center">
      <h2 class="mb-4">Sponsors for the {event.yearIdentifier} event</h2>
      {
        (event.financialSponsors) && (
          <div className={`mb-4 ${styles.financialSponsorsContainer}`}>
            <h3>Financial sponsors</h3>
            <Container fluid class="mt-3">

            <div>
            { (event.financialSponsors.tier1 && event.financialSponsors.tier1.length > 0) &&
              (
                <div class="my-4">
                    <Row className="align-items-center justify-content-center">
                      {event.financialSponsors.tier1.map(s => {
                        return (
                          <Col lg={s.reducedSize ? 4 : 10} xs={s.reducedSize ?  10 : 14} className="my-4">
                            <a href={s.websiteLink} target="_blank" rel="noreferrer">
                              <Img fluid={s.logo.fluid} alt={s.name}></Img>
                            </a>
                          </Col>
                        )
                      })}
                    </Row>
                 </div>
               )
             }
                  { (event.financialSponsors.tier2 && event.financialSponsors.tier2.length > 0) &&
                   (
                    <div class="my-4">
                    <Row className="align-items-center justify-content-center">
                      {event.financialSponsors.tier2.map(s => {
                        return (
                          <Col lg={s.reducedSize ? 3 : 8} xs={s.reducedSize ? 6 : 12} className="my-4">
                            <a href={s.websiteLink} target="_blank" rel="noreferrer">
                              <Img fluid={s.logo.fluid} alt={s.name}></Img>
                            </a>
                          </Col>
                        )
                      })}
                    </Row>
                    </div>
                   )
                  }
                </div>
            </Container>
          </div>
        )
      }
      { 
        ((event.productSponsors.tier1 || event.productSponsors.tier2 || event.productSponsors.tier3)) &&
        (
          <div className={styles.productSponsorsContainer}>
            <h3>Product sponsors</h3>
            <Container class="mt-4">
              {
                (event.productSponsors.tier1 || event.productSponsors.tier2 || event.productSponsors.tier3) &&
                (
                  <div>
                    { (event.productSponsors.tier1 && event.productSponsors.tier1.length > 0) &&
                      (
                        <div class="my-4">
                          <Row className="align-items-center justify-content-center mb-2">
                            {event.productSponsors.tier1.map(s => {
                              return (
                                <Col className="mb-4" lg={3} xs={6}>
                                  <a href={s.websiteLink} target="_blank" rel="noreferrer">
                                    <Img fluid={s.logo.fluid} alt={s.name}></Img>
                                  </a>
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      )
                    }

                    { (event.productSponsors.tier2 && event.productSponsors.tier2.length > 0) &&
                      (
                        <div class="my-4">
                          <Row className="align-items-center justify-content-center">
                            {event.productSponsors.tier2.map(s => {
                              return (
                                <Col className="mb-4" lg={2} xs={5}>
                                  <a href={s.websiteLink} target="_blank" rel="noreferrer">
                                    <Img fluid={s.logo.fluid} alt={s.name}></Img>
                                  </a>
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      )
                    }

                    { (event.productSponsors.tier3 && event.productSponsors.tier3.length > 0) &&
                      (
                        <div class="mt-4">
                          <Row className="align-items-center justify-content-center">
                            {event.productSponsors.tier3.map(s => {
                              return (
                                <Col className="mb-4" lg={2} xs={3}>
                                  <a href={s.websiteLink} target="_blank" rel="noreferrer">
                                    <Img fluid={s.logo.fluid} alt={s.name}></Img>
                                  </a>
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </Container>
          </div>
        )
      }
    </div>
  )


}

